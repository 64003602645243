export enum DisplayResolution {
    XS = "XS",
    SM = "SM",
    MD = "MD",
    LG = "LG",
    XL = "XL"
}

export namespace DisplayResolution {
    export function getCurrentResolution(): DisplayResolution {
        const width = window.innerWidth;

        if (width < 576) {
            return DisplayResolution.XS;
        } else if (width >= 576 && width < 768) {
            return DisplayResolution.SM;
        } else if (width >= 768 && width < 992) {
            return DisplayResolution.MD;
        } else if (width >= 992 && width < 1200) {
            return DisplayResolution.LG;
        } else {
            return DisplayResolution.XL;
        }
    }
}
