import { NgIfContext } from '@angular/common';
import { Directive, AfterContentChecked, TemplateRef, ViewContainerRef, Input} from '@angular/core';
import { DisplayResolution } from '../models/DisplayResolution';

@Directive({
  selector: '[ngxResponsiveColumn]'
})
export class NgxResponsiveColumnDirective implements AfterContentChecked {
  private _visible = false;
  private _threshold: string;
  private _context: NgIfContext = new NgIfContext();

  @Input() 
  set ngxResponsiveColumn(value: string) {
    this._threshold = value;
  }

  @Input() 
  set ngxResponsiveColumnCondition(condition: any) {
    this._context.$implicit = condition;
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef
  ) {}

  ngAfterContentChecked() {
    if (!this.precondition()) {
      this.hide();
      return;
    }
  
    const allowedThresholds: Record<DisplayResolution, string[]> = {
      [DisplayResolution.XS]: ["xs"],
      [DisplayResolution.SM]: ["sm", "xs"],
      [DisplayResolution.MD]: ["md", "sm", "xs"],
      [DisplayResolution.LG]: ["lg", "md", "sm", "xs"],
      [DisplayResolution.XL]: ["xl", "lg", "md", "sm", "xs"],
    };
  
    const currentResolution = DisplayResolution.getCurrentResolution();
  
    if (allowedThresholds[currentResolution]?.includes(this._threshold)) {
      this.show();
    } else {
      this.hide();
    }
  }

  precondition() {
    return this._context.$implicit;
  }

  show() {
    if (this._visible) {
      return;
    }
    this._viewContainer.createEmbeddedView(this._templateRef);
    this._visible = true;
  }

  hide() {
    if (!this._visible) {
      return;
    }
    this._viewContainer.clear();
    this._visible = false;
  }

}